<script>
export default {
  name: "ReportsView",
};
</script>

<template>
  <v-slide-x-transition mode="out-in">
    <router-view></router-view>
  </v-slide-x-transition>
</template>
