<template>
  <v-container fluid>
    <v-row>
      <v-col lg="8" md="8" cols="12">
        <ReportGraph
          :loading="loading"
          :title="$t('report.setup.buysGraph.graph.buys.title')"
          :build-spec="buildBuysSpec"
          :daily-reports="dailyReports"
          trend-diff-field="buysCompPM_MTD"
          trend-total-field="buysComp_MTD"
          :heights="{ xs: '350px', sm: '350px', md: '100%', default: '100%' }"
          :bar-widths="{
            xs: 5,
            sm: 10,
            md: 7,
            default: 20,
          }"
        />
      </v-col>
      <v-col lg="4" md="4" sm="12">
        <v-row>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.buysGraph.metric.buys.title')"
              :value="totals.buys"
              type="currency"
            />
          </v-col>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.buysGraph.metric.buysComp.title')"
              :value="totals.buysComp"
              type="currency"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <!-- TODO - if showing percentage, should work the same as percent cell -> receive 0.3 for 30% -->
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.buysGraph.metric.tradePct.title')"
              :value="totals.tradePct * 100"
              type="percent"
            />
          </v-col>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.buysGraph.metric.tradePctComp.title')"
              :value="totals.tradePctComp * 100"
              type="percent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.buysGraph.metric.tradesCount.title')"
              :value="totals.tradesCount"
            />
          </v-col>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.buysGraph.metric.tradesCountComp.title')"
              :value="totals.tradesCountComp"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" sm="12">
        <ReportGraph
          :loading="loading"
          :title="$t('report.setup.buysGraph.graph.buyCount.title')"
          :build-spec="buildBuysCountSpec"
          :daily-reports="dailyReports"
          trend-diff-field="buysCountCompPM_MTD"
          trend-total-field="buysCountComp_MTD"
          :heights="{ default: '200px' }"
          :bar-widths="{
            xs: 5,
            sm: 10,
            md: 5,
            default: 10,
          }"
        />
      </v-col>
      <v-col md="6" sm="12">
        <ReportGraph
          :loading="loading"
          :title="$t('report.setup.buysGraph.graph.buysAvgWaitTime.title')"
          :build-spec="buildBuysAvgWaitTimeSpec"
          :daily-reports="dailyReports"
          :heights="{ default: '200px' }"
          :bar-widths="{ xs: 5, sm: 10, md: 5, default: 10 }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  buildBuysSpec,
  buildBuysCountSpec,
  buildBuysAvgWaitTimeSpec,
} from "../specs";
import { RaiMetric } from "@/core-ui";
import ReportGraph from "./ReportGraph";

export default {
  name: "BuysGraphs",
  components: {
    RaiMetric,
    ReportGraph,
  },
  props: {
    dailyReports: {
      type: Object,
      default: () => ({
        reports: [],
        totals: [],
      }),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buildBuysSpec() {
      return (...args) => buildBuysSpec(...args);
    },
    buildBuysCountSpec() {
      return (...args) => buildBuysCountSpec(...args);
    },
    buildBuysAvgWaitTimeSpec() {
      return (...args) => buildBuysAvgWaitTimeSpec(...args);
    },
    buysAvgWaitTimeSpecOptions() {
      let domainValues = [0];
      let genDomainValue = 0;
      if (
        this.dailyReports &&
        this.dailyReports.max &&
        this.dailyReports.max["buysAvgWaitTime"]
      ) {
        while (genDomainValue < this.dailyReports.max["buysAvgWaitTime"]) {
          genDomainValue += 30 * 60; // up in 30 minutes intervals
          domainValues.push(genDomainValue);
        }
      }

      if (domainValues.length < 2) {
        // By default show 00:00 to 00:30
        domainValues = [0, 1800];
      }

      return {
        secondsOk: 1200,
        secondsWarning: 1500,
        domainValues,
      };
    },
    totals() {
      return this.dailyReports?.totals || {};
    },
  },
};
</script>
