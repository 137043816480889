<script>
import EmployeeAction from "@/components/employees/EmployeeAction.js";

export default {
  name: "ReportsHome",
  components: { EmployeeAction },
  data() {
    return {
      reports: [
        {
          title: this.$t("report.app.reportsHome.weekly.title"),
          desc: this.$t("report.app.reportsHome.weekly.description"),
          to: { name: "reports.weekly" },
          date: true,
          dateType: "week",
          requiresManager: true,
        },
        {
          title: this.$t("report.app.reportsHome.monthly.title"),
          desc: this.$t("report.app.reportsHome.monthly.description"),
          to: { name: "reports.monthly" },
          date: true,
          dateType: "month",
          requiresManager: true,
        },
        {
          title: this.$t("report.app.reportsHome.agingBackstock.title"),
          desc: this.$t("report.app.reportsHome.agingBackstock.description"),
          to: { name: "reports.backstock" },
          date: false,
        },
        {
          title: this.$t("report.app.reportsHome.bounceback.title"),
          desc: this.$t("report.app.reportsHome.bounceback.description"),
          to: { name: "reports.bounceback" },
          date: false,
        },
        {
          title: this.$t("report.app.reportsHome.buyLog.title"),
          desc: this.$t("report.app.reportsHome.buyLog.description"),
          to: { name: "reports.buylog" },
          date: true,
          dateType: "day",
        },
        {
          title: this.$t("report.app.reportsHome.closeReport.title"),
          desc: this.$t("report.app.reportsHome.closeReport.description"),
          to: { name: "reports.day-close" },
          requiresManager: true,
        },
        {
          title: this.$t("report.app.reportsHome.ledgerJournal.title"),
          desc: this.$t("report.app.reportsHome.ledgerJournal.description"),
          to: { name: "reports.ledger" },
          date: true,
          dateType: "day",
          requiresManager: true,
        },
        {
          title: this.$t("report.app.reportsHome.backstockHistory.title"),
          desc: this.$t("report.app.reportsHome.backstockHistory.description"),
          to: { name: "reports.backstockHistory" },
          date: false,
        },
      ],
    };
  },
  methods: {
    handleClick(rpt) {
      this.$router.push({
        name: rpt.to.name,
      });
    },
    handleCardClick(rpt, handleAction) {
      rpt.requiresManager
        ? handleAction(() => this.handleClick(rpt))
        : this.handleClick(rpt);
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-for="rpt in reports"
        :key="rpt.title"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <EmployeeAction
          v-slot="{ handleAction, isDisabled, listeners }"
          check-manager
        >
          <v-card
            class="report-card"
            @click="handleCardClick(rpt, handleAction)"
          >
            <v-card-title class="text-h5">
              {{ rpt.title }}
            </v-card-title>
            <v-card-text>{{ rpt.desc }}</v-card-text>
            <v-card-actions v-if="rpt.requiresManager">
              <v-spacer />
              <v-icon v-if="isDisabled" v-on="listeners">$lock</v-icon>
              <v-btn
                text
                color="primary"
                :class="`${isDisabled ? 'text--disabled' : ''}`"
                v-on="listeners"
                >View</v-btn
              >
            </v-card-actions>

            <v-card-actions v-else>
              <v-spacer />
              <v-btn text color="primary">View</v-btn>
            </v-card-actions>
          </v-card>
        </EmployeeAction>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.report-card {
  display: grid;
  border-radius: 5px !important;
  grid-template-rows: min-content 1fr 50px;
  height: 190px;
}
</style>
