<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" sm="12">
        <ReportGraph
          :loading="loading"
          :title="$t('report.setup.binsGraph.graph.backstocksAdded.title')"
          :build-spec="buildAddedBackstockSpec"
          :daily-reports="addedBackstockReports"
          :heights="heightsForCategories"
          :bar-widths="{ default: 7 }"
          :spec-options="{
            countTitle: $t(
              'report.setup.binsGraph.graph.backstocksAdded.countTitle'
            ),
          }"
        />
      </v-col>
      <v-col md="6" sm="12">
        <ReportGraph
          :loading="loading"
          :title="$t('report.setup.binsGraph.graph.backstocksPulled.title')"
          :build-spec="buildPulledBackstockSpec"
          :daily-reports="pulledBackstockReports"
          :heights="heightsForCategories"
          :bar-widths="{ default: 7 }"
          :spec-options="{
            countTitle: $t(
              'report.setup.binsGraph.graph.backstocksPulled.countTitle'
            ),
          }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { buildBinsSpec } from "../specs";
import ReportGraph from "./ReportGraph";

const CATEGORY_LINE_HEIGHT = 23;

export default {
  name: "BinsGraphs",
  components: {
    ReportGraph,
  },
  props: {
    addedBackstockReports: {
      type: Object,
      default: () => {},
    },
    pulledBackstockReports: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buildAddedBackstockSpec() {
      return (...args) => buildBinsSpec(...args);
    },
    buildPulledBackstockSpec() {
      return (...args) => buildBinsSpec(...args);
    },
    graphHeight() {
      return (
        Math.max(
          this.addedBackstockReports?.reports.length,
          this.pulledBackstockReports?.reports.length
        ) * CATEGORY_LINE_HEIGHT
      );
    },
    heightsForCategories() {
      return { default: this.graphHeight || "450px" };
    },
  },
};
</script>
