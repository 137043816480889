<template>
  <div>
    <SalesGraphs
      :daily-reports="dailyReports"
      :loading="reportsLoading"
      :show-month-to-date="showMonthToDate"
    />
    <BuysGraphs :daily-reports="dailyReports" :loading="reportsLoading" />
    <BinsGraphs
      :added-backstock-reports="addedBackstockReports"
      :pulled-backstock-reports="pulledBackstockReports"
      :loading="reportsLoading"
    />
    <ReportTable
      :headers="datesToShow"
      :table-data="dailyReports"
      :backstocks-table-data="dailyBackstockReports"
      :loading="reportsLoading || dailyBackstocksLoading"
      :show-days-of-week="showDaysOfWeek"
    />
    <ComparisonDatesInfo
      v-if="startDate && endDate && compStartDate && compEndDate"
      class-name="mt-4"
      :start-date="startDate"
      :end-date="endDate"
      :comp-start-date="compStartDate"
      :comp-end-date="compEndDate"
    />
  </div>
</template>

<script>
import SalesGraphs from "./components/SalesGraphs.vue";
import BuysGraphs from "./components/BuysGraphs.vue";
import BinsGraphs from "./components/BinsGraphs.vue";
import ReportTable from "./components/ReportTable.vue";
import ComparisonDatesInfo from "./components/ComparisonDatesInfo.vue";

import { format, addDays, endOfDay, parseISO, startOfToday } from "date-fns";
import { isCurrentPeriod, periodStart, periodEnd } from "@/utils/datetime";
import { hasRouteDay } from "@/mixins/routerParams";

import {
  DAILY_REPORTS_QUERY,
  BACKSTOCK_REPORTS_QUERY,
  DAILY_BACKSTOCK_REPORTS_QUERY,
} from "./graphql";
import { parseDailyReports, parseDailyBackstockReports } from "../utils";
import {
  REPORTS_WEEK_START_STORAGE_KEY,
  StoreReportViewType,
  BackstockReportsType,
} from "../constants";

export default {
  name: "StoreReportView",
  components: {
    SalesGraphs,
    BuysGraphs,
    BinsGraphs,
    ReportTable,
    ComparisonDatesInfo,
  },
  mixins: [hasRouteDay],

  props: {
    storeId: { type: [String, Number], required: true },
    type: {
      type: String,
      required: true,
      validator: (val) =>
        [StoreReportViewType.WEEK, StoreReportViewType.MONTH].includes(val),
    },
  },

  data() {
    return {
      reportsLoading: false,
      dailyBackstocksLoading: false,
    };
  },

  computed: {
    showDaysOfWeek() {
      return this.type === "week";
    },
    weekStartDayIndex() {
      return window.localStorage.getItem(REPORTS_WEEK_START_STORAGE_KEY) ===
        "true"
        ? 1
        : 0;
    },
    queryStartDate() {
      return periodStart(this.dayDate, this.type, this.weekStartDayIndex);
    },
    queryEndDate() {
      return periodEnd(this.dayDate, this.type, this.weekStartDayIndex);
    },

    startDate() {
      if (
        !this.dailyReports ||
        !this.dailyReports.reports ||
        this.dailyReports.reports.length <= 0
      ) {
        return null;
      }
      return parseISO(this.dailyReports.reports[0].businessDate);
    },
    endDate() {
      if (
        !this.dailyReports ||
        !this.dailyReports.reports ||
        this.dailyReports.reports.length <= 0
      ) {
        return null;
      }
      return parseISO(
        this.dailyReports.reports[this.dailyReports.reports.length - 1]
          .businessDate
      );
    },
    compStartDate() {
      if (
        !this.dailyReports ||
        !this.dailyReports.reports ||
        this.dailyReports.reports.length <= 0
      ) {
        return null;
      }
      return parseISO(this.dailyReports.reports[0].compDate);
    },
    compEndDate() {
      if (
        !this.dailyReports ||
        !this.dailyReports.reports ||
        this.dailyReports.reports.length <= 0
      ) {
        return null;
      }
      return parseISO(
        this.dailyReports.reports[this.dailyReports.reports.length - 1].compDate
      );
    },
    datesToShow() {
      if (!this.startDate || !this.endDate) {
        return [];
      }

      const dates = [];
      let dateToAdd = this.startDate;
      while (dateToAdd <= this.endDate) {
        dates.push(format(dateToAdd, "yyyy-MM-dd"));
        dateToAdd = addDays(dateToAdd, 1);
      }

      return dates;
    },
    showMonthToDate() {
      return isCurrentPeriod(this.dayDate, this.type, this.weekStartDayIndex);
    },
  },

  apollo: {
    addedBackstockReports: {
      query: BACKSTOCK_REPORTS_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          startDate: this.queryStartDate,
          endDate: this.queryEndDate,
          type: BackstockReportsType.ADDED,
        };
      },
      watchLoading(isLoading) {
        this.reportsLoading = isLoading;
      },
      skip() {
        return !this.storeId || !this.queryStartDate || !this.queryEndDate;
      },
      update(data) {
        // Make a structure similar to daily reports
        return { reports: data.backstockReports };
      },
    },
    pulledBackstockReports: {
      query: BACKSTOCK_REPORTS_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          startDate: this.queryStartDate,
          endDate: this.queryEndDate,
          type: BackstockReportsType.PULLED,
        };
      },
      watchLoading(isLoading) {
        this.reportsLoading = isLoading;
      },
      skip() {
        return !this.storeId || !this.queryStartDate || !this.queryEndDate;
      },
      update(data) {
        // Make a structure similar to daily reports
        return { reports: data.backstockReports };
      },
    },
    dailyBackstockReports: {
      query: DAILY_BACKSTOCK_REPORTS_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          startDate: this.queryStartDate,
          endDate: this.queryEndDate,
        };
      },
      watchLoading(isLoading) {
        this.dailyBackstocksLoading = isLoading;
      },
      skip() {
        return !this.storeId || !this.queryStartDate || !this.queryEndDate;
      },
      update(data) {
        return parseDailyBackstockReports(
          data.dailyBackstockReports,
          endOfDay(new Date())
        );
      },
    },
    dailyReports: {
      query: DAILY_REPORTS_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          startDate: this.queryStartDate,
          endDate: this.queryEndDate,
        };
      },
      watchLoading(isLoading) {
        this.reportsLoading = isLoading;
      },
      skip() {
        return !this.storeId || !this.queryStartDate || !this.queryEndDate;
      },
      update(data) {
        return parseDailyReports(data.dailyReports, startOfToday());
      },
    },
  },
};
</script>
