import ReportView from "./Index.vue";
import StoreReportView from "./StoreReportView.vue";
import ReportsHome from "./ReportsHome.vue";
import DayCloseReportView from "./DayCloseReportView.vue";
import LedgerAndJournalView from "./LedgerAndJournalView.vue";

export {
  ReportView,
  StoreReportView,
  ReportsHome,
  DayCloseReportView,
  LedgerAndJournalView,
};
export default ReportView;
