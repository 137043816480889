<template>
  <v-container fluid>
    <v-row>
      <v-col lg="8" md="8" cols="12">
        <ReportGraph
          :loading="loading"
          :title="$t('report.setup.salesGraph.graph.sales.title')"
          :build-spec="buildSalesSpec"
          :daily-reports="dailyReports"
          trend-diff-field="salesCompPM_MTD"
          trend-total-field="salesComp_MTD"
          :heights="{ xs: '350px', sm: '350px', md: '100%', default: '100%' }"
          :bar-widths="{
            xs: 5,
            sm: 10,
            md: 7,
            default: 20,
          }"
        />
      </v-col>
      <v-col lg="4" md="4" sm="12">
        <v-row>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.salesGraph.metric.sales.title')"
              :value="totals.sales"
              type="currency"
            />
          </v-col>
          <v-col cols="6">
            <!-- TODo - calcualte percentage here or in utils.js, not both places -->
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.salesGraph.metric.grossMargin.title')"
              :value="totals.grossMargin * 100"
              type="percent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.salesGraph.metric.salesGoal.title')"
              :value="totals.salesGoal"
              type="currency"
            >
              <template #append>
                <RaiNumber
                  v-if="showMonthToDate"
                  v-slot="{ number }"
                  :value="totals.salesGoal_MTD"
                  type="currency"
                  :decimals="0"
                >
                  {{ number }} MTD
                </RaiNumber>
              </template>
            </RaiMetric>
          </v-col>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.salesGraph.metric.salesComp.title')"
              :value="totals.salesComp"
              type="currency"
            >
              <template #append>
                <RaiNumber
                  v-if="showMonthToDate"
                  v-slot="{ number }"
                  :value="totals.salesComp_MTD"
                  type="currency"
                  :decimals="0"
                >
                  {{ number }} MTD
                </RaiNumber>
              </template>
            </RaiMetric>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.salesGraph.metric.avgTrans.title')"
              :value="totals.avgTrans"
              type="currency"
              :decimals="2"
            />
          </v-col>
          <v-col cols="6">
            <RaiMetric
              :loading="loading"
              :label="$t('report.setup.salesGraph.metric.customerCount.title')"
              :value="totals.customerCount"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" sm="12">
        <ReportGraph
          :loading="loading"
          :title="$t('report.setup.salesGraph.graph.customerCount.title')"
          :build-spec="buildCustomerCountSpec"
          :daily-reports="dailyReports"
          trend-diff-field="customerCountCompPM_MTD"
          trend-total-field="customerCountComp_MTD"
          :heights="{ default: '200px' }"
          :bar-widths="{
            xs: 5,
            sm: 10,
            md: 5,
            default: 10,
          }"
        />
      </v-col>
      <v-col md="6" sm="12">
        <ReportGraph
          :loading="loading"
          :title="$t('report.setup.salesGraph.graph.avgTrans.title')"
          :build-spec="buildAvgTransSpec"
          :daily-reports="dailyReports"
          trend-diff-field="avgTransCompPM_MTD"
          trend-total-field="avgTransComp_MTD"
          :heights="{ default: '200px' }"
          :bar-widths="{
            xs: 5,
            sm: 10,
            md: 5,
            default: 10,
          }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  buildSalesSpec,
  buildCustomerCountSpec,
  buildAvgTransSpec,
} from "../specs";
import { RaiMetric, RaiNumber } from "@/core-ui";
import ReportGraph from "./ReportGraph";

export default {
  name: "SalesGraphs",
  components: {
    RaiMetric,
    ReportGraph,
    RaiNumber,
  },
  props: {
    dailyReports: {
      type: Object,
      default: () => ({
        reports: [],
        totals: [],
      }),
    },
    loading: {
      type: Boolean,
      required: true,
    },
    showMonthToDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buildSalesSpec() {
      return (...args) => buildSalesSpec(...args);
    },
    buildCustomerCountSpec() {
      return (...args) => buildCustomerCountSpec(...args);
    },
    buildAvgTransSpec() {
      return (...args) => buildAvgTransSpec(...args);
    },
    totals() {
      return this.dailyReports?.totals || {};
    },
  },
};
</script>
