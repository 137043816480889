<template>
  <VxTableRow v-bind="$attrs" :mobile="false" v-on="$listeners">
    <th :class="headerCellClass">
      <v-icon
        v-if="dataItem.subItems && dataItem.expanded"
        x-small
        v-text="'$vuetify.icons.minus'"
      ></v-icon>
      <v-icon
        v-if="dataItem.subItems && !dataItem.expanded"
        x-small
        v-text="'$vuetify.icons.plus'"
      ></v-icon>
      <span v-if="!dataItem.subItems">
        {{ dataItem.rowTitle }}
      </span>
      <strong v-if="dataItem.subItems">
        {{ dataItem.rowTitle }}
      </strong>
    </th>

    <ReportTableDayCell
      v-for="(dayData, index) in filteredDays"
      :key="index"
      :day-data="dayData"
      :visible="showDays"
      :type="dataItem.type"
    />

    <ReportTableDayCell :day-data="total" :type="dataItem.type" />
  </VxTableRow>
</template>

<script>
import { VxTableDataCell, VxTableRow } from "@/core-ui";

import { format, addDays, parseISO } from "date-fns";
import ReportTableDayCell from "./ReportTableDayCell.vue";

export default {
  name: "ReportTableListItem",
  components: {
    VxTableRow,
    ReportTableDayCell,
  },
  props: {
    dataItem: {
      type: Object,
      default: () => {},
    },
    startDate: {
      type: String,
      required: true,
    },
    daysCount: {
      type: Number,
      required: true,
    },
    showDays: {
      type: Boolean,
      default: false,
    },
    showWeekBorder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    filteredDays() {
      let days = this.dataItem.days || [];
      const mappedDays = days.map(this.mapDay);

      while (mappedDays.length < this.daysCount) {
        mappedDays.push(
          this.mapDay({
            amount: null,
            date: mappedDays[mappedDays.length - 1]?.date
              ? addDays(parseISO(mappedDays[mappedDays.length - 1]?.date), 1)
              : this.startDate,
          })
        );
      }

      return mappedDays;
    },
    total() {
      return this.mapDay(this.dataItem.total);
    },
    headerCellClass() {
      if (this.dataItem.subItems) {
        return "header-cell";
      }

      return "header-cell pl-7";
    },
  },
  methods: {
    mapDay(day) {
      let klass = "";
      let endOfWeek = false;

      if (this.dataItem.trendPositiveDirection === "up") {
        if (day.amount >= 0) {
          klass = "green--text";
        } else {
          klass = "red--text";
        }
      } else if (this.dataItem.trendPositiveDirection === "down") {
        if (day.amount < 0) {
          klass = "green--text";
        } else {
          klass = "red--text";
        }
      }

      if (day.date) {
        const dateFormatted = parseISO(day.date.replace(/\//g, "-"));
        const dayOfWeek = format(dateFormatted, "i");
        this.endOfWeek = parseInt(dayOfWeek, 10) === 7;

        if (this.endOfWeek && this.showWeekBorder) {
          klass += " end-of-week";
        }
      }

      return {
        ...day,
        endOfWeek,
        class: klass,
      };
    },
  },
};
</script>
