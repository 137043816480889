var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"lg":"8","md":"8","cols":"12"}},[_c('ReportGraph',{attrs:{"loading":_vm.loading,"title":_vm.$t('report.setup.buysGraph.graph.buys.title'),"build-spec":_vm.buildBuysSpec,"daily-reports":_vm.dailyReports,"trend-diff-field":"buysCompPM_MTD","trend-total-field":"buysComp_MTD","heights":{ xs: '350px', sm: '350px', md: '100%', default: '100%' },"bar-widths":{
          xs: 5,
          sm: 10,
          md: 7,
          default: 20,
        }}})],1),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('RaiMetric',{attrs:{"loading":_vm.loading,"label":_vm.$t('report.setup.buysGraph.metric.buys.title'),"value":_vm.totals.buys,"type":"currency"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('RaiMetric',{attrs:{"loading":_vm.loading,"label":_vm.$t('report.setup.buysGraph.metric.buysComp.title'),"value":_vm.totals.buysComp,"type":"currency"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('RaiMetric',{attrs:{"loading":_vm.loading,"label":_vm.$t('report.setup.buysGraph.metric.tradePct.title'),"value":_vm.totals.tradePct * 100,"type":"percent"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('RaiMetric',{attrs:{"loading":_vm.loading,"label":_vm.$t('report.setup.buysGraph.metric.tradePctComp.title'),"value":_vm.totals.tradePctComp * 100,"type":"percent"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('RaiMetric',{attrs:{"loading":_vm.loading,"label":_vm.$t('report.setup.buysGraph.metric.tradesCount.title'),"value":_vm.totals.tradesCount}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('RaiMetric',{attrs:{"loading":_vm.loading,"label":_vm.$t('report.setup.buysGraph.metric.tradesCountComp.title'),"value":_vm.totals.tradesCountComp}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('ReportGraph',{attrs:{"loading":_vm.loading,"title":_vm.$t('report.setup.buysGraph.graph.buyCount.title'),"build-spec":_vm.buildBuysCountSpec,"daily-reports":_vm.dailyReports,"trend-diff-field":"buysCountCompPM_MTD","trend-total-field":"buysCountComp_MTD","heights":{ default: '200px' },"bar-widths":{
          xs: 5,
          sm: 10,
          md: 5,
          default: 10,
        }}})],1),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('ReportGraph',{attrs:{"loading":_vm.loading,"title":_vm.$t('report.setup.buysGraph.graph.buysAvgWaitTime.title'),"build-spec":_vm.buildBuysAvgWaitTimeSpec,"daily-reports":_vm.dailyReports,"heights":{ default: '200px' },"bar-widths":{ xs: 5, sm: 10, md: 5, default: 10 }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }