var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('ReportGraph',{attrs:{"loading":_vm.loading,"title":_vm.$t('report.setup.binsGraph.graph.backstocksAdded.title'),"build-spec":_vm.buildAddedBackstockSpec,"daily-reports":_vm.addedBackstockReports,"heights":_vm.heightsForCategories,"bar-widths":{ default: 7 },"spec-options":{
          countTitle: _vm.$t(
            'report.setup.binsGraph.graph.backstocksAdded.countTitle'
          ),
        }}})],1),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('ReportGraph',{attrs:{"loading":_vm.loading,"title":_vm.$t('report.setup.binsGraph.graph.backstocksPulled.title'),"build-spec":_vm.buildPulledBackstockSpec,"daily-reports":_vm.pulledBackstockReports,"heights":_vm.heightsForCategories,"bar-widths":{ default: 7 },"spec-options":{
          countTitle: _vm.$t(
            'report.setup.binsGraph.graph.backstocksPulled.countTitle'
          ),
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }