<template>
  <thead class="v-data-table-header">
    <tr :class="{ 'vx-data-list-headers': true, collapsed: hideHeader }">
      <VxTableHeaderCell
        v-for="(header, index) in parsedHeaders"
        :key="index"
        type="date"
        :date-format="dateFormat"
        :class="header.cssClass"
        :value="header.label"
        :align="header.align"
      />
    </tr>
  </thead>
</template>

<script>
import { VxTableHeaderCell, TableCellAlign } from "@/core-ui";

import { format, parseISO } from "date-fns";

export default {
  name: "ReportTableHeader",
  components: {
    VxTableHeaderCell,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    showDays: {
      type: Boolean,
      default: false,
    },
    showDaysOfWeek: {
      type: Boolean,
      default: false,
    },
    showWeekBorder: {
      type: Boolean,
      default: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateFormat() {
      if (this.showDaysOfWeek) {
        return "EEEE";
      }
      return "dd";
    },
    parsedHeaders() {
      const headers = this.showDays
        ? this.headers.map((header, index) => {
            const parsedHeader = {
              propertyPath: `cell-day-${index}`,
              label: header,
              cssClass: "day-cell text-right",
              align: "end",
            };

            const dayOfWeek = format(parseISO(header), "i");
            const endOfWeek = parseInt(dayOfWeek, 10) === 7;
            if (endOfWeek && this.showWeekBorder) {
              parsedHeader.cssClass += " end-of-week";
            }

            return parsedHeader;
          })
        : [];
      return [
        {
          propertyPath: "cell-header",
          label: "",
          sortable: false,
          cssClass: "header-cell",
        },
        ...headers,
        {
          propertyPath: "cell-total",
          label: "",
          sortable: false,
          cssClass: "total-cell",
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.collapsed {
  visibility: collapse;
}

th {
  padding-top: 0;
  padding-bottom: 0;

  .header-label {
    // TODO - seee if this style can be picked from veutify
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
</style>
