<template>
  <VxTable :loading="loading" :headers="parsedHeaders">
    <template #head>
      <ReportTableHeader
        :hide-header="isMobile"
        :headers="headers"
        :show-week-border="showWeekBorder"
        :show-days="showDays"
        :show-days-of-week="showDaysOfWeek"
      />
    </template>
    <template #body>
      <tbody>
        <ReportTableListItem
          v-for="dataItem in filteredTableData"
          :key="dataItem.id"
          :start-date="startDate"
          :days-count="daysCount"
          :show-days="showDays"
          :show-week-border="showWeekBorder"
          :data-item="dataItem"
          @click="toggleExpanded(dataItem.id)"
        />
      </tbody>
    </template>
  </VxTable>
</template>

<script>
import { VxTable } from "@/core-ui";
import ReportTableListItem from "./ReportTableListItem.vue";
import ReportTableHeader from "./ReportTableHeader.vue";
import { ReportTableRowType } from "../../constants";

export default {
  name: "ReportTable",
  components: {
    VxTable,
    ReportTableListItem,
    ReportTableHeader,
  },
  props: {
    tableData: {
      type: Object,
      default: () => ({ reports: [], totals: {} }),
    },
    backstocksTableData: {
      type: Object,
      default: () => ({ reports: [], totals: {} }),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showDaysOfWeek: {
      type: Boolean,
      default: false,
    },
    showWeekBorder: {
      type: Boolean,
      default: true,
    },
  },
  data(vm) {
    return {
      showDays: !vm.$vuetify.breakpoint.smAndDown,
      expandedRowsIds: [],
    };
  },
  computed: {
    startDate() {
      return this.headers[0] || null;
    },
    daysCount() {
      return this.headers.length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    parsedHeaders() {
      // Add fake start/end headers - so that count matches cells number
      return [{}, ...this.headers, {}];
    },
    parsedTableData() {
      const agregate = {
        days: {},
        totals: {
          ...this.tableData.totals,
          ...this.backstocksTableData.totals,
        },
      };
      this.tableData.reports.forEach((dayData) => {
        for (const key in dayData) {
          if (
            key !== "businessDate" &&
            key !== "storeId" &&
            key !== "compDate" &&
            key !== "__typename"
          ) {
            if (!agregate.days[key]) {
              agregate.days[key] = [];
            }
            agregate.days[key].push({
              amount: dayData[key],
              date: dayData.businessDate,
            });
          }
        }
      });

      this.backstocksTableData.reports.forEach((dayData) => {
        for (const key in dayData) {
          if (
            key !== "businessDate" &&
            key !== "storeId" &&
            key !== "compDate" &&
            key !== "__typename"
          ) {
            if (!agregate.days[key]) {
              agregate.days[key] = [];
            }
            agregate.days[key].push({
              amount: dayData[key],
              date: dayData.businessDate,
            });
          }
        }
      });

      return [
        this.buildParsedRow("sales", agregate, {
          type: ReportTableRowType.MONEY,
          id: "sales",
          subItems: [
            this.buildParsedRow("salesGoal", agregate, {
              type: ReportTableRowType.MONEY,
            }),
            this.buildParsedRow("salesComp", agregate, {
              type: ReportTableRowType.MONEY,
            }),
            this.buildParsedRow("salesGoalPM", agregate, {
              type: ReportTableRowType.MONEY,
              trendPositiveDirection: "up",
            }),
            this.buildParsedRow("salesCompPM", agregate, {
              type: ReportTableRowType.MONEY,
              trendPositiveDirection: "up",
            }),
          ],
        }),
        this.buildParsedRow("customerCount", agregate, {
          id: "customerCount",
          subItems: [
            this.buildParsedRow("customerCountComp", agregate),
            this.buildParsedRow("customerCountCompPM", agregate, {
              trendPositiveDirection: "up",
            }),
          ],
        }),
        this.buildParsedRow("avgTrans", agregate, {
          id: "avgTrans",
          type: ReportTableRowType.MONEY,
          subItems: [
            this.buildParsedRow("avgTransComp", agregate, {
              type: ReportTableRowType.MONEY,
            }),
            this.buildParsedRow("avgTransCompPM", agregate, {
              type: ReportTableRowType.MONEY,
              trendPositiveDirection: "up",
            }),
          ],
        }),
        this.buildParsedRow("buys", agregate, {
          id: "buys",
          type: ReportTableRowType.MONEY,
          subItems: [
            this.buildParsedRow("buysCount", agregate),
            this.buildParsedRow("buysAvgDollars", agregate, {
              type: ReportTableRowType.MONEY,
            }),
            this.buildParsedRow("buysAvgWaitTime", agregate, {
              type: ReportTableRowType.DURATION,
            }),
            this.buildParsedRow("tradesCount", agregate),
            this.buildParsedRow("tradePct", agregate, {
              type: ReportTableRowType.PERCENTAGE,
            }),
            this.buildParsedRow("buysComp", agregate, {
              type: ReportTableRowType.MONEY,
            }),
            this.buildParsedRow("buysCountComp", agregate),
          ],
        }),
        this.buildParsedRow("backstocksBinsPulled", agregate, {
          id: "backstocksBinsPulled",
          subItems: [this.buildParsedRow("backstocksBinsAdded", agregate)],
        }),
        this.buildParsedRow("laborEfficiency", agregate, {
          id: "laborEfficiency",
          type: ReportTableRowType.MONEY,
          subItems: [
            this.buildParsedRow("laborHours", agregate),
            this.buildParsedRow("payroll", agregate, {
              type: ReportTableRowType.MONEY,
            }),
            this.buildParsedRow("laborPct", agregate, {
              type: ReportTableRowType.PERCENTAGE,
            }),
          ],
        }),
        this.buildParsedRow("taskCompletion", agregate, {
          id: "taskCompletion",
          type: ReportTableRowType.PERCENTAGE,
        }),
      ];
    },
    filteredTableData() {
      if (
        !this.tableData ||
        !this.tableData.reports ||
        !this.tableData.reports.length
      ) {
        return [];
      }

      return this.parsedTableData.reduce((parsedData, itemData) => {
        const expanded = this.expandedRowsIds.includes(itemData.id);
        const subItemsData = expanded ? itemData.subItems : [];
        itemData.expanded = expanded;
        return [...parsedData, itemData, ...subItemsData];
      }, []);
    },
  },
  methods: {
    toggleExpanded(itemId) {
      if (!itemId) {
        return;
      }
      const idIndex = this.expandedRowsIds.indexOf(itemId);
      if (idIndex >= 0) {
        this.expandedRowsIds.splice(idIndex, 1);
      } else {
        this.expandedRowsIds.push(itemId);
      }
    },
    buildParsedRow(dataKey, agregate, additionalProps = {}) {
      return {
        rowTitle: this.$t(`report.app.reportTable.headerColumn.${dataKey}`),
        days: agregate.days[dataKey] || [],
        total: {
          amount: agregate.totals[dataKey],
        },
        // By default we know that most of table rows are amounts, and we need only to override for money/percentage
        type: ReportTableRowType.AMOUNT,
        ...additionalProps,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/scrollbars.scss";

.vx-table {
  @include scrollbars(
    6px,
    map-get($ui, "lighten-2"),
    map-get($ui, "lighten-5")
  );
}

.theme--dark.vx-table {
  @include scrollbars(6px, map-get($ui, "darken-1"), map-get($ui, "darken-5"));
}

::v-deep table .header-cell {
  min-width: 200px;
}

// We need to add background to all table cells so that the first cell which is header cell -> will be drawn over other cells
::v-deep table tr:nth-child(even) {
  td,
  th {
    background-color: var(--v-ui-lighten4);
  }
}

::v-deep table tr:nth-child(odd) {
  td,
  th {
    background-color: var(--v-ui-lighten6);
  }
}

.theme--dark::v-deep table tr:nth-child(even) {
  td,
  th {
    background-color: var(--v-ui-darken3);
  }
}

.theme--dark::v-deep table tr:nth-child(odd) {
  td,
  th {
    // Table background for dark-theme. Can not be found in vuetify css/scss variables.
    background-color: #1e1e1e;
  }
}

::v-deep table .end-of-week {
  border-right: 1px solid var(--v-ui-lighten2);
}

.theme--dark::v-deep table .end-of-week {
  border-right: 1px solid var(--v-ui-darken2);
}

::v-deep table {
  width: auto;
  table-layout: auto;
  min-width: 100%;
}

::v-deep table th:first-child,
::v-deep table td:first-child {
  position: sticky;
  left: 0;
  z-index: 3;
}
</style>
