<template>
  <VxTableDataCell
    v-show="visible"
    :type="cellType"
    :value="dayData.amount"
    :class="dayData.class"
    date-format="HH:mm:ss"
  />
</template>

<script>
import { VxTableDataCell } from "@/core-ui";
import { ReportTableRowType } from "../../constants";

export default {
  name: "ReportTableListItem",
  components: {
    VxTableDataCell,
  },
  props: {
    dayData: {
      type: Object,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    cellType() {
      if (this.type === ReportTableRowType.MONEY) {
        return "currency";
      } else if (this.type === ReportTableRowType.PERCENTAGE) {
        return "percent";
      } else if (this.type === ReportTableRowType.AMOUNT) {
        return "number";
      } else if (this.type === ReportTableRowType.TIME) {
        return "date";
      } else if (this.type === ReportTableRowType.DURATION) {
        return "duration";
      } else {
        return "text";
      }
    },
  },
};
</script>
