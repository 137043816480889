<script>
import { format } from "date-fns";

export default {
  name: "ComparisonDatesInfo",

  props: {
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
    compStartDate: { type: Date, required: true },
    compEndDate: { type: Date, required: true },
    className: { type: String, default: undefined },
  },

  computed: {
    comparisonText() {
      return this.$t("report.app.reportTable.comparisonDatesInfo.compareText", {
        startDate: format(this.startDate, "iiii, MMM do yyyy"),
        endDate: format(this.endDate, "iiii, MMM do yyyy"),
        compStartDate: format(this.compStartDate, "iiii, MMM do yyyy"),
        compEndDate: format(this.compEndDate, "iiii, MMM do yyyy"),
      });
    },
    computedClasses() {
      const classes = {
        "text-center text-caption pa-3 comparison-conatiner": true,
        "theme--dark": this.$vuetify.theme.dark,
      };

      if (this.className) {
        classes[this.className] = true;
      }

      return classes;
    },
  },
};
</script>

<template>
  <div :class="computedClasses">
    {{ comparisonText }}
  </div>
</template>

<style lang="scss" scoped>
.comparison-conatiner {
  background-color: var(--v-ui-lighten4);
}

.theme--dark.comparison-conatiner {
  background-color: var(--v-ui-darken3);
}
</style>
