<template>
  <RaiGraph
    :loading="loading"
    :title="title"
    :spec="graphSpec"
    :trend="graphTrend"
    :height="graphHeight"
    chart-height="100%"
  />
</template>

<script>
import { RaiGraph } from "@/core-ui";

export default {
  name: "ReportGraph",
  components: { RaiGraph },
  props: {
    title: {
      type: String,
      default: null,
    },
    dailyReports: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: true,
    },
    trendDiffField: {
      type: String,
      default: undefined,
    },
    trendTotalField: {
      type: String,
      default: undefined,
    },
    heights: {
      type: Object,
      required: true,
    },
    barWidths: {
      type: Object,
      required: true,
    },
    buildSpec: {
      type: Function,
      required: true,
    },
    specOptions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    graphHeight() {
      if (this.$vuetify.breakpoint.xs) {
        return this.heights.xs || this.heights.default;
      } else if (this.$vuetify.breakpoint.smAndDown) {
        return this.heights.sm || this.heights.default;
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return this.heights.md || this.heights.default;
      } else if (this.$vuetify.breakpoint.lgAndDown) {
        return this.heights.lg || this.heights.default;
      } else {
        return this.heights.xl || this.heights.default;
      }
    },
    graphStyle() {
      if (this.$vuetify.breakpoint.xs) {
        return {
          barWidth: this.barWidths.xs || this.barWidths.default,
        };
      } else if (this.$vuetify.breakpoint.smAndDown) {
        return {
          barWidth: this.barWidths.sm || this.barWidths.default,
        };
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return {
          barWidth: this.barWidths.md || this.barWidths.default,
        };
      } else if (this.$vuetify.breakpoint.lgAndDown) {
        return {
          barWidth: this.barWidths.lg || this.barWidths.default,
        };
      } else {
        return {
          barWidth: this.barWidths.xl || this.barWidths.default,
        };
      }
    },
    graphSpec() {
      return this.buildSpec({
        dailyReports: this.dailyReports,
        barWidth: this.graphStyle.barWidth,
        // TODO - do this in the actual function ?
        isDark: this.$vuetify.theme.dark,
        ...this.specOptions,
      });
    },
    graphTrend() {
      if (
        !this.trendDiffField ||
        !this.trendTotalField ||
        !this.dailyReports?.totals
      ) {
        return undefined;
      }

      return (
        this.dailyReports.totals[this.trendDiffField] /
        this.dailyReports.totals[this.trendTotalField]
      );
    },
  },
};
</script>
